import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "@/store";
import { getAccessToken } from "@/utils/auth"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/home',

  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/views/Login')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/resetPassword')
  },
  {
    path: '/',
    component: () => import("@/views/Layout"),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue'),

      },
      {
        path: '/product',
        name: 'Product',
        component: () => import('../views/Product.vue'),
        meta:{
          cur:4
        }

      },{
        path: '/productdetail',
        name: 'productdetail',
        component: () => import('../views/productDetail.vue'),
        meta:{cur:4}
      },{
        path: '/specialist',
        name: 'specialist',
        component: () => import('../views/Supplier/specialist.vue'),
        meta:{cur:5}
      },{
        path: '/supplier',
        name: 'supplier',
        component: () => import('../views/Supplier/index.vue'),
        meta:{cur:5}
      },{
        path: '/supplierdetail',
        name: 'supplierdetail',
        component: () => import('../views/Supplier/detail.vue'),
        meta:{cur:5}
      },{
        path: '/supplier-review',
        name: 'supplier-review',
        component: () => import('../views/Supplier/review.vue'),
        meta:{cur:5}
      },{
        path: '/supplier-cats',
        name: 'supplier-cats',
        component: () => import('../views/Supplier/suppliercats.vue'),
        meta:{cur:5}
      },{
        path: '/supplier-ventor',
        name: 'supplier-ventor',
        component: () => import('../views/Supplier/vendor.vue'),
        meta:{cur:5}
      },{
        path: '/supplier-company',
        name: 'supplier-company',
        component: () => import('../views/Supplier/company.vue'),
        meta:{cur:5}
      },{
        path: '/supplier-companylist',
        name: 'supplier-companylist',
        component: () => import('../views/Supplier/companyList.vue'),
        meta:{cur:5}
      },
      //promotions
      {
        path: '/promotions',
        name: 'promotions',
        component: () => import('../views/Promotion/index.vue'),
        meta:{cur:0}
      },
      {
        path: '/promotions/1',
        name: 'promotions-steveInfo',
        component: () => import('../views/Promotion/steveInfo.vue'),
        meta:{cur:0}
      },{
        path: '/promotions/2',
        name: 'promotions-marcoInfo',
        component: () => import('../views/Promotion/marcoInfo.vue'),
        meta:{cur:0}
      },{
        path: '/privateresidence',
        name: 'privateresidence',
        component: () => import('../views/Promotion/privateresidence.vue'),
        meta:{cur:0}
      },
      //sdx Brand
      {
        path: '/sdx-brand',
        name: 'sdx-brand',
        component: () => import('../views/Sldxbrand/index.vue'),
        meta:{cur:1}
      },{
        path: '/sdx-brandinfo',
        name: 'sdx-brandinfo',
        component: () => import('../views/Sldxbrand/detail.vue'),
        meta:{cur:1}
      },
      //strategic
      {
        path: '/strategic',
        name: 'strategic',
        component: () => import('../views/Strategic/index.vue'),
        meta:{cur:2}
      },
      {
        path: '/strategic/brand',
        name: 'strategic-brand',
        component: () => import('../views/Strategic/brand.vue'),
        meta:{cur:2}
      },{
        path: '/strategic/brand-detail',
        name: 'strategic-brand-detail',
        component: () => import('../views/Strategic/detail.vue'),
        meta:{cur:2}
      },
      //approved
      {
        path: '/approved',
        name: 'approved',
        component: () => import('../views/Approved/index.vue'),
        meta:{cur:3}
      },
      {
        path: '/approved/brand',
        name: 'approved-brand',
        component: () => import('../views/Approved/brand.vue'),
        meta:{cur:3}
      },
      //project share
      {
        path: '/guideline',
        name: 'guideline',
        component: () => import('../views/Guideline/index'),
        meta:{cur:-1}
      },{
        path: '/deliverable',
        name: 'deliverable',
        component: () => import('../views/Guideline/deliverable'),
        meta:{cur:6}
      },{
        path: '/deliverable-detail',
        name: 'deliverable-detail',
        component: () => import('../views/Guideline/deliverabledetail.vue'),
        meta:{cur:6}
      },{
        path: '/project-photo',
        name: 'project-photo',
        component: () => import('../views/Guideline/projectphoto.vue'),
        meta:{cur:6}
      },{
        path: '/project-photo-album',
        name: 'project-photo-album',
        component: () => import('../views/Guideline/projectalbum.vue'),
        meta:{cur:6}
      },{
        path: '/project-file',
        name: 'project-file',
        component: () => import('../views/Guideline/projectfile.vue'),
        meta:{cur:-1}
      },{
        path: '/design-guideline',
        name: 'design-guideline',
        component: () => import('../views/Guideline/designguideline.vue'),
        meta:{cur:-1}
      },{
        path: '/project-doc',
        name: 'project-doc',
        component: () => import('../views/Guideline/projectdoc.vue'),
        meta:{cur:-1}
      },{
        path: '/my-inform',
        name: 'my-inform',
        component: () => import('../views/Inform.vue')
      },{
        path: '/wish-list',
        name: 'wish-list',
        component: () => import('../views/wishList.vue')
      },{
        path: '/review',
        name: 'review',
        component: () => import('../views/Review.vue')
      },{
        path: '/news',
        name: 'news',
        component: () => import('../views/News.vue')
      },{
        path: '/vote',
        name: 'vote',
        component: () => import('../views/Vote/vote'),
      },{
        path: '/vote-list',
        name: 'vote-list',
        component: () => import('../views/Vote/voteList'),
      },{
        path: '/vote-record',
        name: 'vote-record',
        component: () => import('../views/Vote/voteRecord'),
      },{
        path: '/search',
        name: 'search',
        component: () => import('../views/Search.vue')
      },
    ]
  },



]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
      if (to.meta.savedPosition) {
        return { x: 0, y: to.meta.savedPosition || 0 }
      }
      if (savedPosition) {
          return savedPosition
      } else {
          if (from.meta.keepAlive) {
              from.meta.savedPosition = document.body.scrollTop
          }
          return { x: 0, y: to.meta.savedPosition || 0 }
      }
  }
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach(async (to, from, next) => {
  if(to.meta.cur != -1){
    store.commit('SET_CURRENT', to.meta.cur);
  }else{
    let mid = to.query.mid;
    if(to.name == 'guideline'){
      if(mid==20 || mid==8 || mid==3){
        store.commit('SET_CURRENT', 6);
      }else if(mid==6){
        store.commit('SET_CURRENT', 9);
      }
    }
    if(to.name == 'design-guideline' || to.name == 'project-file'){
      if(mid==20){
        store.commit('SET_CURRENT', 8);
      }else if(mid==1){
        store.commit('SET_CURRENT', 7);
      }else{
        store.commit('SET_CURRENT', 9);
      }
    }
  }

  const loginUrl = process.env.VUE_APP_LOGIN_URL;
  const resetUrl = process.env.VUE_APP_RESET_PASSWORD_URL;
  store.dispatch('page/UpdateRedirectPath')
  if (getAccessToken()) {
		// reload token from storage everytime to ensure synchronize
		await store.dispatch('RestoreToken')
    // check if external access
    let validAccessData = await store.dispatch('CheckValidAccessData')
    if (!validAccessData) {
      let userInfoResponse = await store.dispatch('GetUserInfo')
      if (userInfoResponse === 'success') {
        if (to.path == loginUrl) {
          return next('/home')
        }
        return next()
      }
      return next(loginUrl)
    } else {
      if (to.path == loginUrl) {
        return next('/home')
      }
      return next()
    }
  } else if (to.path !== loginUrl && to.path !== resetUrl) {
    return next(loginUrl)
  }

  return next()

 })

export default router
