<template>
  <div>
    <el-dialog class="dialog dialog" :visible.sync="isDialog" open="0" close-on-click-modal close-on-press-escape :show-close="true" >
        <div class="reminder-content">
            <div v-for="(reminderMain,index) in reminderList.filter(main => main.list.length > 0)" :key="index">
              <div>
                <strong >{{$t('common.' + reminderMain.type )}}</strong>
                <div
                  v-for="(reminderItem,index) in reminderMain.list"
                  :key="index"
                  :class="'reminder-item ' + (reminderItem?.path?'reminder-has-path':'')"
                  @click="getToContent(reminderItem?.path)"
                >
                  <span>{{reminderItem?.["name_" + $i18n.locale] ?? ""}}</span>
                </div>
              </div>
            </div>
        </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    data(){
        return{
            isDialog :true
        }
    },
    props: {
      reminderList: {
        type: Array
      },
    },
    methods:{
      getToContent(path) {
        if (!path) return;
        this.isDialog = false;
        this.$router.push(path);
      }
    }
}
</script>

<style lang="scss" scoped>
.dialog {
  padding: 7.5px;
}
/deep/ .el-dialog{
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto !important;
    width: 100%;
    max-width: 380px;
}
/deep/ .el-dialog__body{
    padding: 0;

}
.reminder-content{
    padding-top: 15px;
    padding-bottom: 15px;
    strong{
        display: block;
        margin-bottom: 15px;
        font-size: 18px;
        word-break: break-word;
        padding: 0 15px;
    }
    .reminder-item{
        padding: 20px;
        border-top: solid 1px #ddd;
        overflow: hidden;
        border-radius: 0 0 4px 4px;
        .btn{
            padding: 6px 12px;
            font-size: 13px;
            background-color: #ecf0f1;
            margin: 3px 3px;
            &:hover{
                background-color: #bdc3c7;
                color: #000;
            }
        }
    }
    .reminder-has-path{
      cursor: pointer;
    }
}
</style>
