<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="deliverables pdl15 pdr15">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div>
              {{ topicData?.['name_' + $i18n.locale] ?? "" }}
            </div>
          </div>
          <div class="name mgb20 tc">
            <h6 class="">{{ $i18n.t("vote.vote_intro") }}</h6>
            <div class="remark-content">
              <div
                v-for="(remrk, rIdx) in getRemarkList()"
                :key="'remark' + rIdx"
              >
                {{ remrk }}
              </div>
            </div>
          </div>
          <div class="name mgb20 tc">
            <h6 class="">{{ $i18n.t("vote.vote_rule") }}</h6>
            <ol class="rule-content">
              <li
                v-for="(rule, rIdx) in getRuleList()"
                :key="'rule' + rIdx"
              >
                {{ rule }}
              </li>
            </ol>
          </div>
        </b-col>
        <b-col md="12">
          <el-checkbox-group
            v-model="selectedVoteIds"
            @change="handleCheckedVoteChange"
          >
          <template v-if="Object.values(votingList).length > 0">
            <ul class="album-list flex-around flex-wrap">
              <li
                v-for="(item, i) in votingList" :key="i"
                :class="getClass(item.id)"
              >
                <a
                  :href="'/#/vote-record?id=' + item.id"
                  class="img-link"
                >
                  <div class="mood-img">
                    <img class="img" v-lazy="imgurl + item.path+'/s300'" />
                  </div>
                </a>
                <label>
                  <h2>{{ item?.project_no}} {{ item?.['name_' + $i18n.locale] }}</h2>
                  <!-- <span>{{ item.id }}-{{ item.team_ids }}</span> -->
                  <el-checkbox
                    class="hover-checkbox"
                    :label="item.id"
                    :disabled="!itemEditable(item.id)"
                  >
                  </el-checkbox>
                </label>
              </li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
              <li class="forJustify"></li>
            </ul>
          </template>
          </el-checkbox-group>
        </b-col>

        <b-col md="12">
          <div class="vote-btn ">
            <b-button
              v-if="ableToEditVote"
              @click="clearVote"
              variant="light"
              size="sm"
              >{{ $t("vote.clear") }}</b-button
            >
            <b-button
              v-if="ableToEditVote"
              @click="submitVote"
              variant="light"
              size="sm"
              :disabled="!ableToSubmitVote"
              >{{ $t("vote.submit") }}</b-button
            >
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { voteMain, addVoteRecord } from '@/api/vote'
  export default {
  data() {
    return {
      selectedVoteIds: [],
      id: null,
      userId: null, // user id
      userMainTeamId: null, // user's main team id
      loading: false,
      isActive: false,
      imgurl: process.env.VUE_APP_IMG_URL,
      setImgUrl:process.env.VUE_APP_BASE_API,
      // name: [],
      topicData: {},
      votingRules: {},
      votingList: [],
      votedItemList: [],
      rules: [],
      result: [],
    }
  },
  mounted() {
  },
  created() {
    this.id = this.$route.query.id
    this.getVote()
  },
  computed: {
    ableToEditVote: function () {
      if (!this.isActive) return false;
      let voteCount = this.votedItemList.filter(el => el.status == 1 && el.id > 0).length;
      if (voteCount == 0) return true; // new to vote;
      if (this.votingRules?.revote) return true; // new to vote;
      return false;
    },
    ableToSubmitVote: function () {
      if (!this.isActive) return false;
      for (const [ruleType, ruleData] of Object.entries(this.votingRules)) {
          if (!this.validateVoteRule(ruleType, ruleData)) {
              return false;
          }
      }
      return true;
    },
  },
  methods: {
    itemEditable(itemId) {
        return this.ableToEditVote && (!this.ableToSubmitVote || this.selectedVoteIds.includes(itemId))
    },
    submitVote() {
       let resultVotedList = this.votedItemList.filter(el => el.id > 0);
       this.selectedVoteIds.forEach(voteId => {
          let votedItem = resultVotedList.find(el => el.parent_id == voteId);
          if (!votedItem) {
            resultVotedList.push({
              id: 0,
              parent_id: voteId,
              user_team_id: this.userMainTeamId,
              status: 1
            });
          }
       })
       const data = {
         topic:this.id,
         vote_list:resultVotedList,
       }

       addVoteRecord(data).then((res) => {
          this.$message({
            message: this.$t("vote.vote_success"),
            type: "success",
          });
          this.getVote();
       }).catch(err => {
          this.$message({
            message: this.$t("vote.vote_fail"),
            type: "error",
          });
       });
    },
    validateOperation (condition, value) {
        for (const [operator, compare] of Object.entries(condition)) {
            switch (operator) {
                case ">":
                    return value > compare;
                case "<":
                    return value < compare;
                case "=":
                    return value == compare;
                case ">=":
                    return value >= compare;
                case "<=":
                    return value <= compare;
                default:
            }
        }
        return false;
    },
    validateVoteRule(type, rule, config = []) {
        let voteCount = 0;
        switch (type) {
            case "allow_vote_num":
                voteCount = this.selectedVoteIds.length;
                return this.validateOperation(rule, voteCount);
            case "allow_self_team_vote_num":
                voteCount = this.votingList.filter(el => {
                                          if (!this.selectedVoteIds.includes(el.id)) return false;
                                          if (!this.userMainTeamId) return false;
                                          return el.team_ids.includes(this.userMainTeamId);
                                        })
                                       .length;
                return this.validateOperation(rule, voteCount);
            case "revote":
                // exiting record
                voteCount = this.votedItemList.filter(el => el.status == 1 && el.id > 0).length;
                return rule || voteCount == 0;
        }
        return false;
    },
    clearVote() {
      this.selectedVoteIds = []
    },
    handleCheckedVoteChange() {
      this.selectedVoteIds = this.selectedVoteIds.sort()
    },
    goPage(url) {
      this.$router.push(url)
    },
    getVote() {
      if (!this.id) return ;
      this.loading = true;
      voteMain({id : this.id}).then((res) => {
        this.loading = false;
        let result = res.data;
        this.userMainTeamId = parseInt(result?.user_team_id)
        this.isActive = result?.is_active
        this.topicData = result?.topic ?? {}
        this.votingList = result?.list ?? [];
        this.votedItemList = result?.result ?? [];
        this.votingRules = result?.rules ?? {};
        this.selectedVoteIds = this.votedItemList.map(el => el.parent_id).sort();
      })

    },
    getRemarkList() {
      let remark = this.topicData?.["remark_" + this.$i18n.locale] ?? "";
      return remark.split("\n").filter(el=>el);
    },
    getRuleList() {
      let rule = this.topicData?.["rule_" + this.$i18n.locale] ?? "";
      return rule.split("\n").filter(el=>el);
    },
    getClass(id) {
      return this.selectedVoteIds.includes(id) ? "item-is-checked" : "";
    },
  },
  watch:{
    selectedVoteIds: function(newValue, oldValue) {
		},
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
    span {
      color: #999;
      font-size: 12px;
    }
  }
  .deliverables {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      margin: 0 -15px;

    }
    @media (max-width: 767px) {
      margin: 0 -15px;
    }
    .col-md-12 {
      padding: 0;
    }
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
.width125 /deep/ .el-input__inner {
  width: 125px;
}
.width59 /deep/ .el-input__inner {
  width: 86px;
}
.width72 /deep/ .el-input__inner {
  width: 72px;
}
.width86 /deep/ .el-input__inner {
  width: 86px;
}
.width73 /deep/ .el-input__inner {
  width: 73px;
}
.width60 /deep/ .el-input__inner {
  width: 60px;
}
h6.album-sub-head {
  font-size: 15px;
  color: #aaaaaa;
  margin: 0px 0px 25px;
  text-align: center;
}
.album-list {
  list-style: none;
  margin: 0 auto;
  // max-width: 1100px;
  width: 100%;
  padding: 0 0 20px;
  font-size: 0;
  margin-bottom: 30px;
  align-items: flex-start;

  li.item-is-checked {
    background-color : #409EFF
  }
  li {
    z-index: 0;
    width: 11.5%;
    text-align: center;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 10px;
    vertical-align: top;
    position: relative;
    @media (max-width: 1500px) {
      width: 12.5%;
    }
    @media (max-width: 1023px) {
      width: 20%;
    }
    @media (max-width: 767px) {
      margin: 0 0 20px 0;
      padding: 0 2%;
    }
    @media (max-width: 567px) {
      width: 33.3%;
    }
    .img-link {
      position: relative;
      display: block;
      text-align: center;
      width: 100%;
      padding: 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
        overflow: hidden;
        background: #f2f2f2;
      }
      .mood-img {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        align-items: center;
        .img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          width: auto;
          height: auto;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
        }
      }
    }
    &:hover {
      .img-link {
        /deep/ .el-image img {
          transform: scale(1.1);
        }
      }
    }
    label {
      width: 100%;
      height: 95px;
      cursor: pointer;
      h2 {
        margin: 0;
        padding: 15px 0 8px;
        font-size: 13px;
        max-width: 100%;
        line-height: 18px;
        word-break: break-all;
      }
    }
  }
  .forJustify {
    height: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.select-vote {
  float: right;
  height: 24px;
  /deep/ .el-checkbox__label {
    padding-left: 5px;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    &::before {
      background-color: #FFF;
      height: 2px;
      transform: none;
      left: 2px;
      right: 0;
      top: 6px;
      width: 10px;
    }
  }
  .icon-download {
    font-size: 14px;
    color: #555;
    cursor: pointer;
    border-left: 1px solid #c0c4cc;
    margin-left: 10px;
    padding-left: 10px;
    .text-upload {
      font-size: 14px;
      color: #555;
      margin-right: 5px;
    }
  }
}
.el-checkbox {
  /deep/ .el-checkbox__input {
    .el-checkbox__inner {
      border-color: #C0C4CC;
      width: 16px;
      height: 16px;
      &::after {
        height: 8px;
        border-right-width: 2px;
        border-bottom-width: 2px;
      }
    }
    &.is-indeterminate {
      .el-checkbox__inner{
        border-color: #409EFF;
      }
    }
  }

}
.hover-checkbox {
  height: 20px !important;
  /deep/ .el-checkbox__input {
    visibility: hidden !important;
  }
  /deep/ .el-checkbox__label {
    display: none !important;
  }
}
.is-checked {
  /deep/ .el-checkbox__input {
    visibility: visible !important;
    .el-checkbox__inner{
      border-color: #409EFF;
      width: 16px;
      height: 16px;
      &::after {
        height: 8px;
        border-right-width: 2px;
        border-bottom-width: 2px;
      }
    }
  }
  /deep/ .el-checkbox__label {
    color: inherit;
  }
}

.album-list li:hover {
  /deep/ .el-checkbox__input {
    visibility: visible !important;
  }
}
/deep/.el-loading-mask {
  position: fixed;
}
.detail-dialog /deep/.el-loading-mask {
  position: absolute;
}
.vote-btn {
  text-align: center;
}
.remark-content {
  font-size: 16px;
}
.rule-content {
  text-align: left;
  width: 500px;
  margin-left: calc(50% - 250px);
  font-size: 16px;
}
.rule-content li{
  list-style-type: circle;
}
</style>
