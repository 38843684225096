import request from '@/utils/request'

export function votePopuplist (params) {
  return request({
    url: '/api/vote/popup',
    method: 'GET',
    params:params
  })
}
export function voteTopiclist (params) {
  return request({
    url: '/api/vote/topic',
    method: 'GET',
    params:params
  })
}
export function voteMain (params) {
  return request({
    url: '/api/vote/main',
    method: 'GET',
    params:params
  })
}
export function voteDetail (params) {
  return request({
    url: '/api/vote/detail',
    method: 'GET',
    params:params
  })
}

export function addVoteRecord (data) {
  return request({
    url: '/api/vote/create',
    method: 'POST',
    data
  })
}
