<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="deliverables pdl15 pdr15">
        <b-col md="12">
          <div class="name mgb20 tc">
            <div>
              <span><a :href="'/#/vote-list?id='+ topicId">
                {{ name?.[$i18n.locale] ?? "" }}
              </a></span>
            </div>
          </div>
        </b-col>
        <b-col md="12" v-loading="uploadImg">
            <div>
              <h6 class="album-sub-head"></h6>
              <ul class="album-list flex-around flex-wrap">
                <li v-for="(item, i) in albumList" :key="i">
                  <a
                  :href="imgurl + item.path"
                  data-fancybox="gallery"
                  data-preload="false"
                  :data-caption="item?.['filename_' + $i18n.locale]"
                  class="img-link">
                    <div class="mood-img">
                      <img class="img" v-lazy="imgurl + item.path+'/s300'" />
                    </div>
                  </a>

                  <label>
                    <h2>{{ item?.['filename_' + $i18n.locale] }}</h2>
                  </label>
                </li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
                <li class="forJustify"></li>
              </ul>
            </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { voteDetail } from '@/api/vote'
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox.css'
export default {
  data() {
    return {
      uploadImg:false,
      id: null,
      loading: false,
      imgurl: process.env.VUE_APP_IMG_URL,
      topicId: null,
      name: [],
      albumList: [],
    }
  },
  mounted() {
    Fancybox.bind('[data-fancybox]', {
      // Your options go here
      Thumbs: false,
    })
  },
  created() {
    this.id = this.$route.query.id
    this.getVoteRecord()
  },

  methods: {
    goPage(url) {
      this.$router.push(url)
    },
    getchangevalue(e) {
      console.log(e)
    },
    getVoteRecord() {
      // console.log("called getVoteRecord");
      if (this.id) {
        this.loading = true;
        voteDetail({
          id: this.id,
        }).then((res) => {
          this.loading = false;
          let result = res.data;
          this.topicId = result.topic_id;
          this.name = result.name;
          this.albumList = result.data;
          // this.resultList = res.data;
        })
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
    span {
      color: #999;
      font-size: 12px;
    }
  }
  .deliverables {
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      margin: 0 -15px;

    }
    @media (max-width: 767px) {
      margin: 0 -15px;
    }
    .col-md-12 {
      padding: 0;
    }
  }
}
/deep/ .el-input__inner {
  border: none;
  font-size: 13px;
  color: #555;
  text-align: center;
  padding-left: 0;
}
.width125 /deep/ .el-input__inner {
  width: 125px;
}
.width59 /deep/ .el-input__inner {
  width: 86px;
}
.width72 /deep/ .el-input__inner {
  width: 72px;
}
.width86 /deep/ .el-input__inner {
  width: 86px;
}
.width73 /deep/ .el-input__inner {
  width: 73px;
}
.width60 /deep/ .el-input__inner {
  width: 60px;
}
h6.album-sub-head {
  font-size: 15px;
  color: #aaaaaa;
  margin: 0px 0px 25px;
  text-align: center;
}
.album-list {
  list-style: none;
  margin: 0 auto;
  // max-width: 1100px;
  width: 100%;
  padding: 0 0 20px;
  font-size: 0;
  margin-bottom: 30px;
  align-items: flex-start;

  li {
    z-index: 0;
    width: 11.5%;
    text-align: center;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 10px;
    vertical-align: top;
    position: relative;
    @media (max-width: 1500px) {
      width: 12.5%;
    }
    @media (max-width: 1023px) {
      width: 20%;
    }
    @media (max-width: 767px) {
      margin: 0 0 20px 0;
      padding: 0 2%;
    }
    @media (max-width: 567px) {
      width: 33.3%;
    }
    .img-link {
      position: relative;
      display: block;
      text-align: center;
      width: 100%;
      padding: 0px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
        overflow: hidden;
        background: #f2f2f2;
      }
      .mood-img {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        align-items: center;
        .img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          margin: auto;
          width: auto;
          height: auto;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
        }
      }
    }
    &:hover {
      .img-link {
        /deep/ .el-image img {
          transform: scale(1.1);
        }
      }
    }
    label {
      width: 100%;
      height: 95px;
      cursor: pointer;
      h2 {
        margin: 0;
        padding: 15px 0 8px;
        font-size: 13px;
        max-width: 100%;
        line-height: 18px;
        word-break: break-all;
      }
    }
  }
  .forJustify {
    height: 1px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
}
.select-upload {
  float: right;
  height: 24px;
  /deep/ .el-checkbox__label {
    padding-left: 5px;
  }
  /deep/ .el-checkbox__inner {
    width: 16px;
    height: 16px;
    &::before {
      background-color: #FFF;
      height: 2px;
      transform: none;
      left: 2px;
      right: 0;
      top: 6px;
      width: 10px;
    }
  }
  .icon-download {
    font-size: 14px;
    color: #555;
    cursor: pointer;
    border-left: 1px solid #c0c4cc;
    margin-left: 10px;
    padding-left: 10px;
    .text-upload {
      font-size: 14px;
      color: #555;
      margin-right: 5px;
    }
  }
}
.el-checkbox {
  /deep/ .el-checkbox__input {
    .el-checkbox__inner {
      border-color: #C0C4CC;
      width: 16px;
      height: 16px;
      &::after {
        height: 8px;
        border-right-width: 2px;
        border-bottom-width: 2px;
      }
    }
    &.is-indeterminate {
      .el-checkbox__inner{
        border-color: #409EFF;
      }
    }
  }

}
.hover-checkbox {
  height: 20px !important;
  /deep/ .el-checkbox__input {
    visibility: hidden !important;
  }
  /deep/ .el-checkbox__label {
    display: none !important;
  }
}
.is-checked {
  /deep/ .el-checkbox__input {
    visibility: visible !important;
    .el-checkbox__inner{
      border-color: #409EFF;
      width: 16px;
      height: 16px;
      &::after {
        height: 8px;
        border-right-width: 2px;
        border-bottom-width: 2px;
      }
    }
  }
  /deep/ .el-checkbox__label {
    color: inherit;
  }
}

.album-list li:hover {
  /deep/ .el-checkbox__input {
    visibility: visible !important;
  }
}
/deep/.el-loading-mask {
  position: fixed;
}
.detail-dialog /deep/.el-loading-mask {
  position: absolute;
}
</style>
