<template>
  <div class="content" v-loading="loading">
    <b-container fluid>
      <b-row class="vote-details">
        <b-col cols="12">
          <h5 class="name tc mgb20">{{ $t("common.uservote") }}</h5>
          <div class="download-overview">
            <span :class="[mode == 1 ? 'active' : '']" @click="changeMode(1)">{{
              $t("vote.is_voting")
            }}</span>
            <span :class="[mode == 2 ? 'active' : '']" @click="changeMode(2)">{{
              $t("vote.vote_ended")
            }}</span>
          </div>
        </b-col>
        <b-col xs="12">
          <div class="user-vote" id="voted">
            <div class="vote-container" v-if="votingData.length > 0">
              <div
                class="vote"
                v-for="(item, index) in votingData"
                :key="index"
              >
                <div class="user">
                  <div style="margin: 2px 0 5px">
                    {{ item?.["name_" + $i18n.locale] }}
                  </div>
                </div>

                <div class="vote-details">
                  <div style="margin-bottom: 5px; font-size: 14px">
                  </div>
                  <div v-if="item?.is_voted" class="vote-status">{{ $t("vote.voted") }}</div>
                </div>

                <div class="vote-actions">
                  <router-link
                    class="btn btn-default"
                    :to="{
                      path:
                        '/vote-list?id=' + item.id + ''
                    }"
                  >
                    {{ getVoteMessage(item) }}
                  </router-link>
                </div>
              </div>
            </div>

            <div class="ft14" v-else>{{ $t("designresinfo.no_record") }}</div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { voteTopiclist } from "@/api/vote";
export default {
  data() {
    return {
      mode: 1,
      loading: false,
      activeVotingData: [],
      inactiveVotingData: [],
      staticInfo: {}
    };
  },

  created() {
    this.getVoting();
  },
  computed: {
    votingData() {
      return this.mode == 1
        ? this.activeVotingData
        : this.inactiveVotingData;
    },
  },
  methods: {
    async getVoting() {
      this.loading = true;
      await voteTopiclist({}).then((res) => {
        this.loading = false;
        this.activeVotingData = res.data.active;
        this.inactiveVotingData = res.data.inactive;
        this.staticInfo = res.data.staticInfo;
      });
    },
    changeMode(num) {
      this.mode = num;
      // this.getVoting();
    },
    getVoteMessage(item) {
        if (this.mode == 1 && !item?.is_voted ) {
          return this.$i18n.t("vote.vote");
        }
        return this.$i18n.t("vote.view");
    }
  },
  watch: {
    $route(to) {
      this.getVoting();
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name {
    padding: 20px 0px 20px;
    font-size: 21px;
    color: #333;
  }
}

.vote-details {
  margin: 0 auto;
  max-width: 1180px;
}
.download-overview {
  font-size: 15px;
  margin-bottom: 50px;
  line-height: 12px;
  text-align: center;
  span {
    display: inline-block;
    border-right: 1px solid #cccccc;
    padding-right: 15px;
    margin-right: 10px;
    color: #555555;
    cursor: pointer;
    &:last-child {
      margin-left: 3px;
      border-right: none;
      padding-right: 0px;
      margin-right: 0px;
    }
  }
  .active {
    color: #950c0f;
  }
}

.user-vote {
  /*border-top: 1px solid #DDDDDD;
	margin-top: 40px;
	padding-top: 40px;*/
  text-align: center;
  @media screen and (max-width: 767px) {
    text-align: left;
    .vote-container {
      text-align: center;
    }
    .star-rate,
    .num-vote {
      text-align: left;
    }
    .vote {
      margin: 40px 0 55px;
      .user {
        float: none;
        margin-bottom: 25px;
      }
      .vote-details {
        margin-left: 0;
      }
    }
  }
  .vote-container {
    width: 100%;
    /*max-width: 1100px;*/
    margin: 20px auto 50px;
    /*padding-left: 20px;
    padding-right: 20px;*/
    font-size: 13px;
    .star-rate {
      margin-bottom: 3px;
      .fa {
        font-size: 20px;
        color: #e4a100;
      }
    }
    .num-vote {
      color: #aaaaaa;
    }
    .vote {
      /*margin: 60px 0 85px;*/
      margin: 0 0 30px;
      padding: 0px 0px 30px;
      display: flex;
      border-bottom: 1px solid #e7e7e7;
      .user {
        width: 500px;
        text-align: left;
      }
      &:last-child {
        border-bottom: 0;
      }
      .vote-details {
        /*margin-left: 200px;*/
        /*width: auto;*/
        width: calc(100% - 200px - 500px);
        flex: auto;
        padding-left: 20px;
        padding-right: 30px;
        text-align: left;
        .star-rate {
          margin: 0px 0 20px;
        }
        .comment {
          margin-bottom: 20px;
        }
        .date {
          font-size: 12px;
          color: #aaaaaa;
          margin-top: 8px;
        }
        .vote-actions {
          width: 160px;
        }
        .album-list {
          list-style: none;
          margin: 0px 0 20px;
          /*max-width: 1100px;*/
          width: 100%;
          padding: 0;
          text-align: center;
          text-align: justify;
          text-align: left;
          font-size: 0;
          /*border-bottom: 1px solid #EEE;*/
          li {
            z-index: 0;
            display: inline-block;
            width: 11.5%;
            text-align: center;
            font-size: 15px;
            margin: 0px 1% 5px 0;
            padding: 1px;
            vertical-align: top;
            position: relative;
            /*background: #EEE;
          border: 1px solid #E0E0E0;*/
            a {
              position: relative;
              display: block;
              text-align: center;
              width: 100%;
              padding: 0px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              cursor: pointer;
              overflow: hidden;
              &::before {
                content: "";
                display: block;
                padding-top: 100%;
              }
              h2 {
                text-decoration: none;
                color: #555555;
              }
            }
            .mood-img {
              /*position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;*/

              position: absolute;
              display: flex;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
              align-items: center;
              img {
                display: block;
                /* max-width:180px;
              max-height:180px;*/
                max-width: 100%;
                max-height: 100%;
                margin: auto;
                width: auto;
                height: auto;
                transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out; /** Chrome & Safari **/
                -moz-transition: all 0.3s ease-in-out; /** Firefox **/
                -o-transition: all 0.3s ease-in-out; /** Opera **/
              }
            }
          }
        }
      }
    }
  }
  .btn {
    font-size: 13px;
    font-weight: normal;
    color: inherit;
    background: #f3f3f3;
    padding: 7px 50px 6px;
    border-color: #ccc;
    &:hover {
      border-color: #adadad;
      color: #950c0f;
    }
  }
}
.link-style:hover {
  color: #950c0f !important;
  cursor: pointer;
}
.vote-status {
  text-align: right;
}
</style>
