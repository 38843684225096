<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="light"
      variant="light"
      class="nav-bar"
      fixed="top"
    >
      <div class="container-fluid">
        <div class="navbar-header">
          <b-navbar-brand class="sld-logo">
            <router-link to="/home" class="logo"
              >DRS
              <!--<img class="logo" alt="DRS" src="../assets/images/logo.png"
            />--></router-link>
          </b-navbar-brand>


          <!-- <b-dropdown size="lg"  variant="link" class="sys-drop" toggle-class="text-decoration-none" menu-class="sys-dropdown" no-caret>
            <template #button-content>
              <span></span>
              <span></span>
              <span></span>
            </template>
            <b-dropdown-item :href="getExtUrl('fcms')">OA <span class="temp">(FCMS)</span></b-dropdown-item>
            <b-dropdown-item :href="getExtUrl('crm')">CRM</b-dropdown-item>
            <b-dropdown-item class="portal-home" :href="getExtUrl('portal')"><i class="sld-icon icon-home"></i></b-dropdown-item>
          </b-dropdown> -->
          <HeaderRoute plateform="drs" />
          <div class="flex right-icon">
            <div class="header-Search navProject">
              <div class="input-group">
                <!-- <div class="input-group-btn">
                      <b-button class=" btn-default " variant="outline-dark"><span class="caret">&nbsp;</span></b-button>
                      <ul class="dropdown-menu">
                          <li >搜索物料 / 產品</li>
                          <li>搜索設計成果</li>
                          <li >項目照片</li>
                          <li >學習資源</li>
                      </ul>
                  </div> -->
                <b-form-input
                  id="q"
                  class="input"
                  v-model="searchVal"
                  :placeholder="$t('common.headsearch')"
                  @keyup.enter="searchChange"
                ></b-form-input>
                <b-button
                  class="btn-default btn-search fa fa-search"
                  @click="searchChange"
                ></b-button>
              </div>
            </div>

            <div
              class="icon-User"
              @mouseover="langMouseOver('user')"
              @mouseleave="langMouseLeave"
            >
              <b class="flex ft14"
                ><i class="fa fa-user-circle" style="font-size: 16px"></i>
                <span>{{ userName }}</span></b
              >
              <div class="nav-dropdowndiv" v-show="langDiaslog == 'user'">
                <div class="nav-dropDown-divCen">
                  <div class="dropdown-content">
                    <router-link class="login-item" to="/review">{{
                      $t("common.userreview")
                    }}</router-link>
                    <router-link class="login-item" to="/vote">{{
                      $t("common.uservote")
                    }}</router-link>
                    <router-link class="login-item" to="/reset-password">{{
                      $t("common.changepassword")
                    }}</router-link>
                    <a
                      class="login-item"
                      :href="adminUrl"
                      v-if="showAdminCenter"
                      target="_blank"
                      >{{ $t("common.adminCenter") }}</a
                    >
                    <a class="login-item" @click="loginOut">{{
                      $t("common.loginout")
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="icon-Notice">
              <router-link to="/news"
                ><i class="fa fa-bell-o"></i>
                <b-badge pill variant="danger">{{ badgeCount> 0?badgeCount:'' }}</b-badge>
              </router-link>
            </div>
            <div class="icon-List">
              <router-link to="/my-inform"
                ><i class="sld-icon icon-clipboard"></i
              ></router-link>
            </div>
            <div class="icon-Fav">
              <router-link to="/wish-list"
                ><i class="sld-icon icon-heart-circled"></i
              ></router-link>
            </div>
            <div
              class="icon-Lang navProject navDropDown"
              @mouseover="langMouseOver('lang')"
              @mouseleave="langMouseLeave"
            >
              <i class="sld-icon icon-globe"></i>
              <div
                class="nav-dropdowndiv lang-media"
                v-show="langDiaslog == 'lang'"
              >
                <div class="nav-dropDown-divCen">
                  <div class="dropdown-content" style="flex-wrap: nowrap">
                    <div class="dropdown-group">
                      <span class="group-title">{{
                        $t("productfilter.Region")
                      }}</span>
                      <div
                        class="langitem"
                        :class="item.value == region ? 'active' : ''"
                        v-for="(item, index) in $t('productfilter.regionption')"
                        :key="index"
                        @click="setRegion(item.value)"
                      >
                        {{ item.text }}
                      </div>
                    </div>
                    <div class="dropdown-group">
                      <span class="group-title">{{
                        $t("common.language")
                      }}</span>
                      <div
                        class="langitem"
                        :class="lan == 'en' ? 'active' : ''"
                        @click="setLanguage('en')"
                      >
                        EN
                      </div>
                      <div
                        class="langitem"
                        :class="lan == 'sc' ? 'active' : ''"
                        @click="setLanguage('sc')"
                      >
                        简
                      </div>
                      <div
                        class="langitem"
                        :class="lan == 'tc' ? 'active' : ''"
                        @click="setLanguage('tc')"
                      >
                        繁
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <b-navbar-toggle
              target="#myNavbar"
              @click="navToggle = !navToggle"
            ></b-navbar-toggle>
          </div>
          <div
            :class="navToggle ? 'in' : ''"
            class="bg-light nav navbar-nav navbar-right top-right links collapse navbar-collapse"
            id="myNavbar"
          >
            <div
              class="nav-dropdown"
              @mouseover="showIndex = index"
              @mouseleave="showIndex = -1"
              v-for="(item, index) in navData"
              :key="index"
            >
              <router-link
                :to="item.url"
                class="nav-a-text"
                :class="index == navcurrent ? 'actived' : ''"
              >
                <em @click="checkNav(index)"> {{ item.text }}</em>
              </router-link>
              <div
                class="nav-dropdowndiv"
                v-show="showIndex == index && item.children != ''"
              >
                <div class="nav-dropDown-divCen">
                  <div class="dropdown-content">
                    <ul
                      v-if="index < 4 || index > 5"
                      :style="{
                        columnCount: navData[index].children.length > 10 ? 2 : 1
                      }"
                    >
                      <li
                        v-for="(childItem, inx) in navData[index].children"
                        :key="inx"
                        @click="checkNav(index)"
                      >
                        <router-link
                          v-if="childItem.url != ''"
                          :to="childItem.url"
                        >
                          {{ childItem.text }}</router-link
                        >
                        <span class="braycolor" v-else>
                          {{ childItem.text }}</span
                        >
                      </li>
                    </ul>

                    <div
                      class="dropdown-group"
                      v-for="(childItem, inx) in navData[index].children"
                      :key="inx"
                      v-else-if="index == 4 || index == 5"
                    >
                      <span class="group-title">{{ childItem.text }} </span>
                      <ul
                        :style="{
                          columnCount:
                            navData[index].children[inx].childnode.length > 15
                              ? 2
                              : 1
                        }"
                      >
                        <li
                          v-for="(childnodeItem, i) in navData[index].children[
                            inx
                          ].childnode"
                          :key="i"
                          @click="checkNav(index)"
                        >
                          <!-- <router-link :class="childnodeItem.url==''? 'braycolor':''"  :to="childnodeItem.url"> {{childnodeItem.text}}</router-link> -->
                          <router-link
                            v-if="childnodeItem.url != ''"
                            :to="childnodeItem.url"
                          >
                            {{ childnodeItem.text }}</router-link
                          >
                          <span class="braycolor" v-else>
                            {{ childnodeItem.text }}</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div
                      class="dropdown-group"
                      v-for="(childItem, inx) in navData[index].children"
                      :key="inx"
                      v-else-if="index == 5"
                    >
                      <span class="group-title">{{ childItem.text }}</span>
                      <ul>
                        <li
                          v-for="(childnodeItem, i) in navData[index].children[
                            inx
                          ].childnode"
                          :key="i"
                          @click="checkNav(index)"
                        >
                          <router-link
                            v-if="childnodeItem.url != ''"
                            :to="childnodeItem.url"
                          >
                            {{ childnodeItem.text }}</router-link
                          >
                          <span class="braycolor" v-else>
                            {{ childnodeItem.text }}</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="nav-dropdown mobile-only" v-if="showAdminCenter">
              <a :href="adminUrl" class="nav-a-text" target="_blank">{{
                $t("common.adminCenter")
              }}</a>
            </div>
            <div class="nav-dropdown mobile-only">
              <a class="nav-a-text" @click="loginOut">{{
                $t("common.loginout")
              }}</a>
            </div>
            <div class="nav-dropdown region mobile-only">
              <ul>
                <li>{{ $t("productfilter.Region") }}</li>
                <li
                  class="langitem"
                  :class="item.value == region ? 'active' : ''"
                  v-for="(item, index) in $t('productfilter.regionption')"
                  :key="index"
                  @click="setRegion(item.value)"
                >
                  {{ item.text }}
                </li>
              </ul>
            </div>
            <div class="nav-dropdown lang mobile-only">
              <ul>
                <li>{{ $t("common.language") }}</li>
                <li
                  class="langitem"
                  :class="lan == 'en' ? 'active' : ''"
                  @click="setLanguage('en')"
                >
                  EN
                </li>
                <li
                  class="langitem"
                  :class="lan == 'sc' ? 'active' : ''"
                  @click="setLanguage('sc')"
                >
                  简
                </li>
                <li
                  class="langitem"
                  :class="lan == 'tc' ? 'active' : ''"
                  @click="setLanguage('tc')"
                >
                  繁
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { region } from "@/api/home";
import HeaderRoute from "./HeaderRoute";

export default {
  name: "HeadNav",
  components: {
    HeaderRoute
  },
  data() {
    return {
      showIndex: -1,
      langDiaslog: false,
      navData: [],
      userInfo: {},
      navToggle: false,
      lan: "",
      searchVal: "",
      region: "",
      badgeCount: "",
      adminUrl: process.env.VUE_APP_ADMIN_URL
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userData"));
    this.lan =
      localStorage.getItem("language") != 'null'?localStorage.getItem("language"):this.userInfo.lang;

    if (localStorage.getItem("language") == 'null' && this.userInfo.lang && this.userInfo.lang != 'null') {
      this.setLanguage(this.lan);
    }

    this.navData = this.$t("navData");
    this.region = localStorage.getItem("forRegion");
    this.badgeCount =
      localStorage.getItem("badgeCount") ||
      sessionStorage.getItem("badgeCount");
  },
  watch: {
    $route() {
      localStorage.setItem("language", this.lan);
      localStorage.setItem("badgeCount", sessionStorage.getItem("badgeCount"));
    }
  },
  computed: {
    ...mapGetters(["navcurrent"]),

    userName() {
      if (this.lan == "tc") {
        return this.userInfo.fullname_tc;
      } else if (this.lan == "sc") {
        return this.userInfo.fullname_sc;
      } else {
        return this.userInfo.fullname_en;
      }
    },
    showAdminCenter() {
      let adminRoles = this.userInfo.roles.filter(function (role) {
        return (
          role.id === 1 ||
          role.id === 4 ||
          role.id === 16 ||
          (role.id >= 67 && role.id <= 71) ||
          role.id === 129 ||
          role.id === 295
        );
      });
      if (adminRoles.length >= 1) {
        return true;
      }

      return false;
    }
  },
  mounted() {},
  methods: {
    setRegion(type) {
      localStorage.setItem("forRegion", type);
      const data = {
        region: type
      };
      region(data).then((res) => {
        if (res.status == "success") {
          this.$router.go(0);
        }
      });
    },
    getExtUrl(url) {
      const env = process.env.VUE_APP_ENV;
      let envPostfix = env?"-"+env:"";
      let curSubDomain = "drs" + envPostfix;
      let extSubDomain = url + envPostfix;
      return window.location.origin.replace(curSubDomain, extSubDomain);
    },
    mouseOver(i) {
      this.showIndex = i;
    },
    mouseLeave(i) {
      this.showIndex = i;
    },
    langMouseOver(i) {
      this.langDiaslog = i;
    },
    langMouseLeave() {
      this.langDiaslog = false;
    },
    loginOut() {
      this.$store.dispatch("LogOut").then(() => {
        this.$message({
          message: "Verification failed, please login again",
          type: "warning"
        });
        location.reload();
        window.localStorage.clear();
      });
    },
    setLanguage(lan) {
      localStorage.setItem("language", lan);
      sessionStorage.setItem("language", lan);
      location.reload();
    },
    checkNav(inx) {
      this.navToggle = false;
      // this.$store.commit('SET_CURRENT', inx);
    },
    searchChange() {
      this.$router.push(`/search?val=${this.searchVal}`);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/style/scss/nav.scss";
//  @import "@/assets/style/scss/fontello.scss";
</style>
